import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import BePartOfTeam from "../pages/BePartOfTeam/BePartOfTeam";
import BuldingMallorca from "../pages/BuildingMallorca/BuldingMallorca";
import ContactUs from "../pages/ContactUs/ContactUs";
import Counselor from "../pages/Counselor/Counselor";
import CounselorMoreDetails from "../pages/Counselor/CounselorMoreDetails";
import DataProtection from "../pages/DataProtection/DataProtection";
import Error from "../pages/Error";
import Favorites from "../pages/Favorites/Favorites";
import Footer from "../layouts/Footer";
// import AddBook from "../fetaures/books/AddBook";
import Header from "../layouts/Header";
import Home from "../pages/Home/Home";
import HorizontalDE from "../pages/Lionsgate/HorizontalIFrameDE";
import HorizontalES from "../pages/Lionsgate/HorizontalIFrameES";
import HorizontalIN from "../pages/Lionsgate/HorizontalIFrameIN";
import Impressum from "../pages/Impressum/Impressum";
import InfoGuide from "../pages/InfoGuide/InfoGuide";
import LifestyleGuide from "../pages/LifestyleGuide/LifestyleGuide";
import Neubau from "../pages/PropertySearch/Neubau";
import OurTeam from "../pages/OurTeam/OurTeam";
import Partner from "../pages/partner/partner";
import PresentationOfProperties from "../pages/PresentationOfProperties/PresentationOfProperties";
import PropertyDetails from "../pages/PropertyDetails/PropertyDetails";
import PropertySearch from "../pages/PropertySearch/PropertySearch";
import React from "react";
import RealEstateSales from "../pages/RealEstateSales/RealEstateSales";
import RedirectToSearch from "../pages/Intermediate/RedirectToSearch";
import ScrollToTop from "./ScrollToTop";
import ServiceGarantie from "../pages/ServiceGarantie/ServiceGarantie";
import VideoTouren from "../pages/VideoTouren/VideoTouren";

const Index = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <main>
        <Switch>
          <Route path={"/redirect-to-search"} component={RedirectToSearch} />
          <Route exact path="/" component={Home} />
          <Route path="/PropertySearch" component={PropertySearch} />
          <Route path="/immobilien/kaufen/:slug" component={PropertySearch} />

          <Route path="/immobilien/:slug" component={PropertySearch} />
          {/* <Route path="/immobiliengutachter" component={PropertySearch} /> */}

          <Route path="/:lang/immobilien" component={PropertySearch} />
          <Route
            path="/:lang/propertydetails/:ort/:id-:objektart/:refId"
            component={PropertyDetails}
          />
          <Route
            path="/propertydetails/:ort/:id-:objektart/:refId"
            component={PropertyDetails}
          />
          <Route path="/verkauf" component={RealEstateSales} />
          <Route path="/unser-team" component={OurTeam} />
          {/* <Route path="/ratgeber" component={Counselor} />
          <Route
            path="/ratgeberdetails/:slug"
            component={CounselorMoreDetails}
          /> */}
          <Route path="/service-garantie" component={ServiceGarantie} />
          <Route path="/partner" component={Partner} />
          <Route path="/lifestyle_guide" component={LifestyleGuide} />
          <Route path="/video-touren" component={VideoTouren} />
          <Route
            path="/buildingBauen-auf-Mallorca"
            component={BuldingMallorca}
          />
          <Route
            path="/kontakt-immobilienmakler-mallorca"
            component={ContactUs}
          />
          <Route path="/join-team" component={BePartOfTeam} />
          <Route path="/impressum" component={Impressum} />
          <Route path="/datenschutz" component={DataProtection} />
          <Route path="/favorites" component={Favorites} />
          <Route path="/horizontal" component={HorizontalIN} />
          <Route path="/horizontal-de" component={HorizontalDE} />
          <Route path="/horizontal-en" component={HorizontalES} />

          <Route
            path="/presentation-of-properties"
            component={PresentationOfProperties}
          />
          <Route path="/info-guide" component={InfoGuide} />
          {/* <Route component={Error} /> */}
          <Redirect to="/" />
        </Switch>
      </main>
      <Footer />
    </BrowserRouter>
  );
};

export default Index;
